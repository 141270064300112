import { memo, useId } from "react";
import type { SVGProps } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `user-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C10.3431 18 9 19.3431 9 21V29C9 29.5523 8.55228 30 8 30C7.44772 30 7 29.5523 7 29V21C7 18.2386 9.23858 16 12 16H20C22.7614 16 25 18.2386 25 21V29C25 29.5523 24.5523 30 24 30C23.4477 30 23 29.5523 23 29V21C23 19.3431 21.6569 18 20 18H12Z"
        fill={`url(#${gradientId})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 15C18.7614 15 21 12.7614 21 10C21 7.23858 18.7614 5 16 5C13.2386 5 11 7.23858 11 10C11 12.7614 13.2386 15 16 15ZM16 17C19.866 17 23 13.866 23 10C23 6.13401 19.866 3 16 3C12.134 3 9 6.13401 9 10C9 13.866 12.134 17 16 17Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="7.20454"
          y1="24.6333"
          x2="27.1707"
          y2="16.9991"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.322917" stopColor="#9A455A" />
          <stop offset="1" stopColor="#9A455A" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const User = memo(SvgComponent);
