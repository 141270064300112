"use client";

import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

import { ArrowLeft } from "../../icons";
import { MenuItem, NextLink } from "../MenuItem/MenuItem";

type MenuStructure = {
  text: string;
  to: Array<LinkItem>;
};
interface LinkItem {
  text: string;
  to: LinkOrMenuStructure;
}

type LinkOrMenuStructure = string | Array<LinkItem>;

interface Props {
  menu: MenuStructure;
  onChange?: (itemText: string) => void;
  Link: NextLink;
}

export const BlockMenu: React.FC<Props> = ({ menu, onChange, Link }) => {
  const [currentMenu, setCurrentMenu] = useState<MenuStructure | LinkItem>(menu);
  const [previousMenu, setPreviousMenu] = useState<MenuStructure | LinkItem>(menu);
  const [title, setTitle] = useState<string>("Wat wil je regelen?");
  return (
    <div>
      {title !== menu.text && (
        <div
          className="mb-4 flex items-center justify-start hover:cursor-pointer"
          onClick={() => {
            previousMenu && setCurrentMenu(previousMenu);
            setTitle("Wat wil je regelen?");
            if (onChange) {
              onChange("Wat wil je regelen?");
            }
          }}
        >
          <ArrowLeft color="#9A455A" data-testid="blockmenu-back" width={16} />
          <span className="text-label-xs text-secondary-burgundy ml-2">Terug</span>
        </div>
      )}
      <h3 className="text-h4-sm text-secondary-burgundy mb-4 font-bold">{title}</h3>
      <AnimatePresence initial={false}>
        <div>
          {typeof currentMenu.to !== "string" &&
            currentMenu.to.map((menuItem: LinkItem) => (
              <motion.div
                key={menuItem.text}
                initial={{ opacity: 0, x: -300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 300 }}
                data-testid={`block-menu-animated-${menuItem.text}`}
              >
                <MenuItem
                  to={typeof menuItem.to === "string" ? menuItem.to : "#"}
                  onClick={() => {
                    if (typeof menuItem.to === "object") {
                      setCurrentMenu((prev: LinkItem) => {
                        setPreviousMenu(prev);
                        setTitle(menuItem.text);
                        if (onChange) {
                          onChange(menuItem.text);
                        }
                        return menuItem;
                      });
                    }
                  }}
                  text={menuItem.text}
                  Link={Link}
                />
              </motion.div>
            ))}
        </div>
      </AnimatePresence>
    </div>
  );
};
