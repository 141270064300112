import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 24,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.6459 9.86002L15.1914 11.0805C15.4565 11.035 15.7249 10.9986 16.003 10.9986C18.542 10.9986 20.6054 12.9856 20.7709 15.4856L22.3119 16.7021C22.351 16.405 22.4009 16.11 22.4009 15.805C22.4009 12.27 19.5359 9.40002 16.0009 9.40002C15.1659 9.40002 14.3759 9.57002 13.6459 9.86002ZM16.0009 25.4C10.676 25.4 5.78595 21.801 3.25095 16.035C3.22995 15.98 3.20345 15.845 3.15595 15.81C3.15869 15.7527 3.18525 15.6209 3.19443 15.5919C3.83643 14.1309 4.62943 12.8114 5.53893 11.6519L4.28643 10.6629C3.28343 11.9389 2.41943 13.3784 1.72993 14.9484C1.67895 15.17 1.55595 15.55 1.55595 15.8C1.55595 16.0489 1.63405 16.43 1.7294 16.6515C4.4419 22.8215 9.8094 26.9565 15.9544 26.9565C18.3609 26.9565 20.6449 26.3085 22.6994 25.1585L21.3124 24.0635C19.681 24.935 17.8709 25.4 16.0009 25.4ZM16.0009 6.20002C21.3259 6.20002 26.216 9.79902 28.7509 15.565C28.7707 15.619 28.7971 15.7507 28.7999 15.7872C28.7971 15.8444 28.7706 15.9763 28.7614 16.0052C28.1194 17.4662 27.3269 18.7857 26.4174 19.9447L27.6699 20.9337C28.6729 19.6577 29.5364 18.2187 30.2264 16.6487C30.3217 16.4272 30.3999 16.0452 30.3999 15.7972C30.3999 15.5484 30.3217 15.1672 30.2264 14.9457C27.5139 8.77572 22.1464 4.64072 16.0014 4.64072C13.5949 4.64072 11.3114 5.28872 9.2564 6.43822L10.6434 7.53322C12.321 6.66652 14.1309 6.20002 16.0009 6.20002ZM16.0009 22.2C16.8349 22.2 17.629 22.029 18.3594 21.7386L16.8134 20.5181C16.5481 20.5637 16.2794 20.6003 16.0009 20.6003C13.4609 20.6003 11.3965 18.6113 11.2325 16.1123L9.69095 14.895C9.60595 15.195 9.60095 15.49 9.60095 15.8C9.60095 19.335 12.466 22.2 16.0009 22.2ZM31.696 27.17L1.29595 3.17102C0.905951 2.89837 0.447751 2.95851 0.172751 3.30307C-0.100699 3.64997 -0.0412987 4.15307 0.305551 4.42757L30.7055 28.4276C30.851 28.545 31.0259 28.6 31.201 28.6C31.4369 28.6 31.6712 28.4953 31.829 28.2961C32.1009 27.905 32.041 27.445 31.696 27.17Z"
      fill="#0B1423"
    />
  </svg>
);

export const EyeSlashed = memo(SvgComponent);
