import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  className?: string;
  containerClass?: string;
  dataBlock?: string;
}

export const Grid: React.FC<Props> = ({ className, containerClass, children, dataBlock }) => {
  return (
    <div data-block={dataBlock} className={`flex justify-center ${containerClass || ""}`.trim()}>
      <div
        className={`
      tablet:mx-4 tablet:grid-cols-12 tablet:px-0 desktop-s:mx-8
      desktop-s:max-w-screen-desktop-xl desktop-s:gap-x-8
      grid w-full max-w-full grid-cols-4 gap-x-6 px-4 ${className ?? ""}`}
      >
        {children}
      </div>
    </div>
  );
};
