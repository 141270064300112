import { SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `arrow-left-duo-dark-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="30.9833"
        y="16.9725"
        width="28"
        height="1"
        rx="0.5"
        transform="rotate(180 30.9833 16.9725)"
        stroke={`url(#${gradientId})`}
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77609 17.1796C1.38557 16.7891 1.38557 16.1559 1.77609 15.7654L13.7761 3.76537C14.1666 3.37484 14.7998 3.37484 15.1903 3.76537C15.5808 4.15589 15.5808 4.78906 15.1903 5.17958L3.89741 16.4725L15.1903 27.7654C15.5808 28.1559 15.5808 28.7891 15.1903 29.1796C14.7998 29.5701 14.1666 29.5701 13.7761 29.1796L1.77609 17.1796Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="31.4833"
          y1="18.4725"
          x2="60.4833"
          y2="18.4725"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.53125" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const ArrowLeftDuoDark = memo(SvgComponent);
