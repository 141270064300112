import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.20603 21.5853L4.36849 22.7478C4.64362 23.0229 5.08973 23.0229 5.36492 22.7478L15.9996 12.1383L26.6344 22.7477C26.9095 23.0228 27.3556 23.0228 27.6308 22.7477L28.7933 21.5852C29.0684 21.3101 29.0684 20.864 28.7933 20.5888L16.4978 8.29345C16.2227 8.01831 15.7766 8.01831 15.5014 8.29345L3.20603 20.5889C2.93084 20.8641 2.93084 21.3102 3.20603 21.5853Z"
      fill={color}
    />
  </svg>
);

export const ChevronUp = memo(SvgComponent);
