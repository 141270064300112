import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 24,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6.9823C9.05417 6.9823 6.66667 9.35924 6.66667 12.292C6.66667 15.2248 9.05542 17.6018 12 17.6018C14.9433 17.6018 17.3333 15.224 17.3333 12.2962C17.3333 9.36339 14.9458 6.9823 12 6.9823ZM12 16.2743C9.79458 16.2743 8 14.4881 8 12.292C8 10.096 9.79458 8.30973 12 8.30973C14.2058 8.30973 16 10.0943 16 12.2925C16 14.4865 14.1708 16.2743 12 16.2743ZM23.8542 11.5495C21.5958 6.46377 17.1208 3 12 3C6.87917 3 2.40375 6.46792 0.144542 11.5495C0.065125 11.7694 0 12.0846 0 12.292C0 12.4985 0.0650833 12.8147 0.144542 12.9985C2.405 18.1203 6.87917 21.5841 12 21.5841C17.1208 21.5841 21.5958 18.117 23.8542 13.0346C23.9333 12.8147 24 12.4621 24 12.292C24 12.0846 23.9333 11.7694 23.8542 11.5495ZM22.6333 12.4663C20.5125 17.2699 16.4375 20.2566 12 20.2566C7.5625 20.2566 3.4875 17.2707 1.375 12.487C1.3575 12.4414 1.33542 12.3294 1.29583 12.3003C1.29811 12.2528 1.32025 12.1434 1.3279 12.1193C3.48667 7.27683 7.5625 4.32743 12 4.32743C16.4375 4.32743 20.5125 7.31333 22.625 12.0971C22.6414 12.1419 22.6635 12.2511 22.6658 12.2814C22.6625 12.3294 22.6417 12.4414 22.6333 12.4663Z"
      fill="#0B1423"
    />
  </svg>
);

export const EyeOpen = memo(SvgComponent);
