import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.5" y="15.5" width="28" height="1" rx="0.5" stroke={color} strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7071 15.2929C31.0976 15.6834 31.0976 16.3166 30.7071 16.7071L18.7071 28.7071C18.3166 29.0976 17.6834 29.0976 17.2929 28.7071C16.9024 28.3166 16.9024 27.6834 17.2929 27.2929L28.5858 16L17.2929 4.70711C16.9024 4.31658 16.9024 3.68342 17.2929 3.29289C17.6834 2.90237 18.3166 2.90237 18.7071 3.29289L30.7071 15.2929Z"
      fill={color}
    />
  </svg>
);

export const ArrowRight = memo(SvgComponent);
