import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.018 13.15a.75.75 0 1 0 1.084 1.037L8 9.067l4.898 5.12a.75.75 0 1 0 1.084-1.037L9.038 7.981l4.943-5.168a.75.75 0 0 0-1.084-1.037L8 6.896l-4.898-5.12a.75.75 0 0 0-1.084 1.037L6.962 7.98l-4.944 5.17Z"
      fill={color}
    />
  </svg>
);

export const Close = memo(SvgComponent);
