import { memo, SVGProps } from "react";
import * as React from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.7188 9.625L15.2188 11.125C15.7812 11.3438 16.0938 11.9375 15.9688 12.5312L15.2188 16.0312C15.0938 16.5938 14.5938 17.0312 14 17.0312C13.7812 17.0312 13.5938 17 13.4062 17C13.2812 17 13.125 17 13 16.9688C5.71875 16.4688 0 10.4062 0 3C0 2.4375 0.40625 1.90625 0.96875 1.78125L4.46875 1.03125C5.0625 0.90625 5.65625 1.21875 5.875 1.78125L7.375 5.28125C7.59375 5.78125 7.46875 6.375 7.03125 6.71875L5.75 7.78125C6.59375 9.21875 7.78125 10.4062 9.21875 11.25L10.2812 9.96875C10.625 9.53125 11.2188 9.40625 11.7188 9.625ZM13.9688 16C14.0938 16 14.1875 15.9375 14.2188 15.8125L14.9688 12.3125C15 12.1875 14.9375 12.0938 14.8438 12.0312L11.3438 10.5312C11.2188 10.5 11.125 10.5312 11.0312 10.5938L10 11.875C9.6875 12.25 9.15625 12.3438 8.71875 12.0938C7.125 11.1875 5.8125 9.875 4.90625 8.28125C4.65625 7.84375 4.75 7.3125 5.125 7L6.40625 5.96875C6.46875 5.875 6.5 5.78125 6.46875 5.65625L4.96875 2.15625C4.90625 2.0625 4.8125 2 4.6875 2.03125L1.1875 2.78125C1.0625 2.78125 1 2.90625 1 3C1 10.1875 6.8125 16 13.9688 16Z"
      fill={color}
    />
  </svg>
);

export const PhoneIcon = memo(SvgComponent);
