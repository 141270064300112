import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect
      x="30.9833"
      y="16.9725"
      width="28"
      height="1"
      rx="0.5"
      transform="rotate(180 30.9833 16.9725)"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.77605 17.1796C1.38552 16.7891 1.38552 16.1559 1.77605 15.7654L13.776 3.76539C14.1666 3.37486 14.7997 3.37486 15.1903 3.76539C15.5808 4.15591 15.5808 4.78908 15.1903 5.1796L3.89737 16.4725L15.1903 27.7654C15.5808 28.1559 15.5808 28.7891 15.1903 29.1796C14.7997 29.5701 14.1666 29.5701 13.776 29.1796L1.77605 17.1796Z"
      fill={color}
    />
  </svg>
);

export const ArrowLeft = memo(SvgComponent);
