import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" viewBox="0 0 26 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 2.625 24.25 21.75H1.75L13 2.625Z"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 10.375a1 1 0 0 1 1-1h.25a1 1 0 0 1 1 1v.22a1 1 0 0 1-1 1h-.25a1 1 0 0 1-1-1v-.22Zm0 3.75a1 1 0 0 1 1-1h.25a1 1 0 0 1 1 1v4.75a1 1 0 0 1-1 1h-.25a1 1 0 0 1-1-1v-4.75Z"
      fill={color}
    />
  </svg>
);

export const Warning = memo(SvgComponent);
