import clsx from "clsx";

import Step from "./components/Step";
import type { StepProps } from "./components/Step";

export type StepIndicatorProps = {
  steps: Omit<StepProps, "stepNumber">[];
  theme?: "light" | "dark";
  variant?: "horizontal" | "vertical";
  className?: string;
};

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  steps,
  className,
  theme = "light",
  variant = "horizontal",
}) => {
  return (
    <ol
      className={clsx(
        "tablet:gap-6 desktop-s:gap-x-8 flex flex-row flex-wrap gap-x-2 gap-y-4 font-sans text-lg text-white",
        theme === "dark" && "text-black",
        variant === "vertical" && "flex-col gap-y-6",
        className
      )}
    >
      {steps.map((step, index) => (
        <li
          key={step.title}
          className={clsx(
            variant === "horizontal" &&
              "desktop-s:[&_.step-title]:not-sr-only [&_.step-title]:sr-only",
            step.isActive && "desktop-s:grow-0 grow [&_.step-title]:not-sr-only ",
            variant === "vertical" &&
              `relative
              [&:not(:last-child)]:before:absolute [&:not(:last-child)]:before:ml-[19px]
              [&:not(:last-child)]:before:mt-[40px] [&:not(:last-child)]:before:h-[calc(100%-1rem)]
              [&:not(:last-child)]:before:w-0.5
              ${
                theme === "dark"
                  ? "[&:not(:last-child)]:before:bg-secondary-burgundy"
                  : "[&:not(:last-child)]:before:bg-white"
              }`
          )}
        >
          <Step {...step} stepNumber={index + 1} theme={theme} />
        </li>
      ))}
    </ol>
  );
};
