import { SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `arrow-down-duo-dark-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="16.5"
        y="1.5"
        width="28"
        height="1"
        rx="0.5"
        transform="rotate(90 16.5 1.5)"
        stroke={`url(#${gradientId})`}
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 30.7071C16.3166 31.0976 15.6834 31.0976 15.2929 30.7071L3.2929 18.7071C2.90237 18.3166 2.90237 17.6834 3.2929 17.2929C3.68342 16.9024 4.31658 16.9024 4.70711 17.2929L16 28.5858L27.2929 17.2929C27.6834 16.9024 28.3166 16.9024 28.7071 17.2929C29.0976 17.6834 29.0976 18.3166 28.7071 18.7071L16.7071 30.7071Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="17"
          y1="2"
          x2="46"
          y2="2"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.53125" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ArrowDownDuoDark = memo(SvgComponent);
