import { FC } from "react";
import { ArrowRight } from "../../icons";

interface CircleArrowRightProps {
  size?: number;
  whiteArrow?: boolean;
}

export const CircleArrowRight: FC<CircleArrowRightProps> = ({ size = 8, whiteArrow }) => {
  const TAILWIND_SCALE = 4;
  const width = size * TAILWIND_SCALE + "px";
  return (
    <div className="transition-1 border-gradient-base from-secondary-burgundy hover:from-primary-mint group-hover:from-primary-mint max-w-fit cursor-pointer p-2 before:rounded-full before:bg-gradient-to-r before:p-[2px] hover:pl-3 hover:pr-1 group-hover:pl-3 group-hover:pr-1">
      <ArrowRight width={width} color={whiteArrow ? "white" : "currentColor"} />
    </div>
  );
};
