import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill={color} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 25.818c5.422 0 9.818-4.396 9.818-9.818S21.422 6.182 16 6.182 6.182 10.578 6.182 16s4.396 9.818 9.818 9.818ZM16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
      fill="url(#a)"
    />
    <path d="M16 11.086a1 1 0 0 1-1-1V10a1 1 0 1 1 2 0v.086a1 1 0 0 1-1 1ZM16 24a1 1 0 0 1-1-1v-9.096a1 1 0 1 1 2 0V23a1 1 0 0 1-1 1Z" />
    <defs>
      <linearGradient id="a" x1={4} y1={16} x2={28} y2={16} gradientUnits="userSpaceOnUse">
        <stop stopColor={color} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export const Tooltip = memo(SvgComponent);
