import clsx from "clsx";
import { formatCurrency } from "../../utils/format-currency";

export interface Props {
  title: string;
  className?: string;
}
/**
 * @TODO: This component is a work in progress and is only a working version of the design.
 * Since it is not clear yet how we are going to integrate it into the application, no props or i18n label functionality has been added.
 * Whenever the implementation is decided, we can decide how to fill the table with data
 */
export const CalculationSummary: React.FC<Props> = ({ title, className }) => {
  return (
    <div
      className={clsx(
        "bg-grey-light tablet:px-8 tablet:pt-6 desktop-s:max-w-[480px] overflow-hidden rounded-lg px-6 pt-4 shadow",
        className
      )}
    >
      <table className="calculation-summary  z-10 w-full  table-auto text-left font-sans">
        <caption className="text-h4-sm mb-6 text-left font-bold">{title}</caption>
        <tbody>
          <tr>
            <th
              className="text-h4-sm text-secondary-aubergine font-bold"
              id="calculation-summary-car-costs"
              colSpan={2}
              scope="col"
            >
              Kosten auto
            </th>
          </tr>
          <tr>
            <th id="calculation-summary-purchase-price" className="font-normal" scope="row">
              Aanschafprijs incl. BTW
            </th>
            <td
              headers="calculation-summary-car-costs calculation-summary-purchase-price"
              className="text-right font-bold"
            >
              {formatCurrency(2899.12)}
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-vat" className="font-normal" scope="row">
              BTW
            </th>
            <td
              className="text-right font-bold"
              headers="calculation-summary-car-costs calculation-summary-vat"
            >
              {formatCurrency(-4820)}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="tablet:w-[110px] ml-auto mr-0 h-0 w-[80px] border-b border-black"></div>
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-total-ex-vat" className="font-normal" scope="row">
              Prijs van het object excl. BTW
            </th>
            <td
              headers="calculation-summary-car-costs calculation-summary-total-ex-vat"
              className="text-right font-bold"
            >
              {formatCurrency(23280)}
            </td>
          </tr>
          <tr>
            <th
              id="calculation-summary-loan-details"
              className="text-h4-sm text-secondary-aubergine font-bold"
              colSpan={2}
              scope="col"
            >
              Te financieren
            </th>
          </tr>
          <tr>
            <th id="calculation-summary-down-payment" className="font-normal" scope="row">
              Aanbetaling
            </th>
            <td
              headers="calculation-summary-loan-details calculation-summary-down-payment"
              className="text-right font-bold"
            >
              {formatCurrency(6515.37)}
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-trade-in-amount" className="font-normal" scope="row">
              Inruilbedrag
            </th>
            <td
              headers="calculation-summary-loan-details calculation-summary-trade-in-amount"
              className="text-right font-bold"
            >
              {formatCurrency(-14876.03)}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="tablet:w-[110px] ml-auto mr-0 h-0 w-[80px] border-b border-black"></div>
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-to-finance" className="font-normal" scope="row">
              Te financieren bedrag
            </th>
            <td
              headers="calculation-summary-loan-details calculation-summary-to-finance"
              className="text-right font-bold"
            >
              {formatCurrency(17250)}
            </td>
          </tr>
          <tr>
            <th
              id="calculation-summary-contract-details"
              className="text-h4-sm text-secondary-aubergine font-bold"
              colSpan={2}
              scope="col"
            >
              Duur contract
            </th>
          </tr>
          <tr>
            <th id="calculation-summary-duration" className="font-normal" scope="row">
              Looptijd
            </th>
            <td
              headers="calculation-summary-contract-details calculation-summary-duration"
              className="text-right font-bold"
            >
              72 maanden
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-final-term" className="font-normal" scope="row">
              Slottermijn
            </th>
            <td
              headers="calculation-summary-contract-details calculation-summary-final-term"
              className="text-right font-bold"
            >
              {formatCurrency(0)}
            </td>
          </tr>
          <tr>
            <th id="calculation-summary-fees" className="pb-4 font-normal" scope="row">
              Eenmalige administratiekosten
            </th>
            <td
              headers="calculation-summary-contract-details calculation-summary-fees"
              className="pb-4 text-right font-bold"
            >
              {formatCurrency(150)}
            </td>
          </tr>
          <tr className="text-menu relative z-[1] after:absolute after:inset-0 after:z-[-1] after:-mx-8 after:-mb-1 after:bg-white after:content-['']">
            <th
              id="calculation-summary-monthly-cost"
              scope="row"
              className="relative z-[1] py-6 font-bold"
            >
              Bedrag per maand
            </th>
            <td
              className="relative z-[1] py-6 text-right font-bold"
              headers="calculation-summary-monthly-cost"
            >
              {formatCurrency(150)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
