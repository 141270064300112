import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={10.25} stroke={color} strokeWidth={2} />
    <path
      d="M13.125 17a1 1 0 0 1-1 1h-.25a1 1 0 0 1-1-1v-.22a1 1 0 0 1 1-1h.25a1 1 0 0 1 1 1V17Zm.076-3.606a1 1 0 0 1-.997.917h-.408a1 1 0 0 1-.997-.917l-.584-7.062a1 1 0 0 1 .996-1.082h1.578a1 1 0 0 1 .996 1.082l-.584 7.062Z"
      fill={color}
    />
  </svg>
);

export const Error = memo(SvgComponent);
