import clsx from "clsx";
import Link from "next/link";
import type { LinkProps } from "next/link";

import { Checkmark } from "../../../icons";

export type StepProps = {
  isActive: boolean;
  isCompleted: boolean;
  stepNumber: number;
  title: string;
  className?: string;
  href?: LinkProps["href"];
  theme?: "light" | "dark";
  onClick?: () => void;
};

export const Step = ({
  isActive,
  isCompleted,
  title,
  stepNumber,
  className,
  theme = "light",
}: StepProps) => {
  return (
    <h4
      className={clsx(
        "desktop-s:grid-cols-[calc(40px+0.5rem)_1fr] grid grid-cols-[calc(40px+0.5rem)_1fr]",
        theme === "dark" ? "text-black" : "text-white",
        className
      )}
    >
      <span
        className={clsx(
          "mr-2 inline-flex size-[40px] items-center justify-center rounded-full border",
          (isActive || isCompleted) && "bg-secondary-burgundy border-secondary-burgundy",
          isActive && "font-bold",
          theme === "dark" && "border-secondary-burgundy text-white"
        )}
      >
        {isCompleted ? (
          <Checkmark width={16} height={16} data-testid="checkmark" />
        ) : (
          <span className={clsx(theme === "dark" && !isActive && "text-secondary-burgundy")}>
            {stepNumber}
          </span>
        )}
      </span>
      <span className={clsx("step-title self-center text-lg", isActive && "font-bold")}>
        {title}
      </span>
    </h4>
  );
};

const WithLink = (Component: typeof Step) => (props: StepProps) => {
  if (props.isCompleted && !props.isActive && props.href) {
    return (
      <Link href={props.href}>
        <Component {...props} />
      </Link>
    );
  }
  return <Component {...props} />;
};
const WithOnClick = (Component: typeof Step) => (props: StepProps) => {
  if (props.isCompleted && !props.isActive && props.onClick) {
    return (
      <div onClick={props.onClick} className="hover:cursor-pointer">
        <Component {...props} />
      </div>
    );
  }
  return <Component {...props} />;
};

export default WithOnClick(WithLink(Step));
