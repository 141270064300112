import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.5443 3.20639L23.7067 4.3688C23.9819 4.64393 23.9819 5.09004 23.7067 5.36523L13.0973 16L23.7067 26.6348C23.9819 26.9099 23.9819 27.356 23.7067 27.6312L22.5443 28.7937C22.2691 29.0688 21.823 29.0688 21.5478 28.7937L9.25243 16.4982C8.97729 16.2231 8.97729 15.777 9.25243 15.5018L21.5478 3.20639C21.823 2.9312 22.2691 2.9312 22.5443 3.20639Z"
      fill={color}
    />
  </svg>
);

export const ChevronLeft = memo(SvgComponent);
