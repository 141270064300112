import { SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `arrow-up-duo-dark-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="15.5"
        y="30.5"
        width="28"
        height="1"
        rx="0.5"
        transform="rotate(-90 15.5 30.5)"
        stroke={`url(#${gradientId})`}
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 1.29289C15.6834 0.902369 16.3166 0.902369 16.7071 1.29289L28.7071 13.2929C29.0976 13.6834 29.0976 14.3166 28.7071 14.7071C28.3166 15.0976 27.6834 15.0976 27.2929 14.7071L16 3.41421L4.70711 14.7071C4.31658 15.0976 3.68342 15.0976 3.29289 14.7071C2.90237 14.3166 2.90237 13.6834 3.29289 13.2929L15.2929 1.29289Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="15"
          y1="32"
          x2="44"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.53125" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const ArrowUpDuoDark = memo(SvgComponent);
