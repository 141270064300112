import clsx from "clsx";
import { LinkProps } from "next/link";

import { ArrowRight } from "../../icons";

export type NextLink = React.ForwardRefExoticComponent<
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode;
    } & React.RefAttributes<HTMLAnchorElement>
>;
interface Props {
  to: string;
  text: string;
  onClick?: () => void;
  className?: string;
  Link: NextLink;
}
export const MenuItem: React.FC<Props> = ({ to, text, onClick, className, Link }) => {
  return (
    <Link href={to} scroll={to === "#" ? false : true}>
      <div
        data-testid={`menuItem-${text}`}
        onClick={onClick}
        className={clsx(
          "flex",
          "justify-between",
          "border-b-2",
          "border-b-grey-dolphin",
          "pt-4",
          "pb-2",
          "align-middle",
          "hover:cursor-pointer",
          "hover:fill-secondary-aubergine",
          "hover:text-secondary-aubergine",
          className
        )}
      >
        <div className="text-menu-sm font-bold ">{text}</div>
        <ArrowRight width={24} />
      </div>
    </Link>
  );
};
