import { memo, useId } from "react";
import type { SVGProps } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `menu-duo-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17H30C30.5523 17 31 16.5523 31 16C31 15.4477 30.5523 15 30 15H2Z"
        fill={`url(#${gradientId})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 24C1.44772 24 1 24.4477 1 25C1 25.5523 1.44772 26 2 26H30C30.5523 26 31 25.5523 31 25C31 24.4477 30.5523 24 30 24H2Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C1.44772 6 1 6.44772 1 7C1 7.55228 1.44772 8 2 8H30C30.5523 8 31 7.55228 31 7C31 6.44772 30.5523 6 30 6H2Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="1"
          y1="16"
          x2="31"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.53125" stopColor="#9A455A" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MenuDuo = memo(SvgComponent);
