import { memo, SVGProps } from "react";

const SvgComponent = ({
  color = "currentColor",
  strokeWidth = 2,
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="m2.625 12 6 6L21.75 4.875"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Checkmark = memo(SvgComponent);
