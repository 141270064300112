export const GradientCircle = () => {
  return (
    <svg height="100%" width="100%" overflow="visible" className="tablet:stroke-[10] stroke-[5]">
      <defs>
        <linearGradient id="grad" gradientTransform="rotate(135)">
          <stop offset="10%" stopColor="rgba(144, 64, 85, 1)" />
          <stop offset="85%" stopColor="rgba(89, 89, 89, 0)" />
        </linearGradient>
      </defs>
      <g fill="none">
        <circle cx="50%" cy="50%" r="50%" stroke="url(#grad)" />
      </g>
    </svg>
  );
};
