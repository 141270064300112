import { SVGProps, memo, useId } from "react";

const SvgComponent = ({ width = 32, ...props }: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientId = `chevron-down-duo-light-icon-gradient-${id}`;

  return (
    <svg
      width={width}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 22.7071C16.3166 23.0976 15.6834 23.0976 15.2929 22.7071L3.2929 10.7071C2.90237 10.3166 2.90237 9.68342 3.2929 9.29289C3.68342 8.90237 4.31658 8.90237 4.70711 9.29289L16 20.5858L27.2929 9.29289C27.6834 8.90237 28.3166 8.90237 28.7071 9.29289C29.0976 9.68342 29.0976 10.3166 28.7071 10.7071L16.7071 22.7071Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="28.7045"
          y1="14.3667"
          x2="3.37457"
          y2="28.3562"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.322917" stopColor="#9A455A" />
          <stop offset="1" stopColor="#9A455A" stopOpacity="0.5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const ChevronDownDuoLight = memo(SvgComponent);
