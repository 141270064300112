import clsx from "clsx";
interface Props {
  fullwidth?: boolean;
  values: Record<string, string | number>;
  className?: string;
}

export const SimpleTable = ({ fullwidth = true, values, className }: Props) => {
  return (
    <table className={clsx(fullwidth && "w-full", className)}>
      <tbody>
        {Object.keys(values).map((key) => (
          <tr key={key}>
            <td className="text-p-sm tablet:text-p font-bold">{key}</td>
            <td className="text-p-sm tablet:text-p-sm font-serif">{values[key]}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
