import clsx from "clsx";

interface Props {
  variant?: keyof typeof variants;
}

const variants = {
  default: "bg-[linear-gradient(90deg,_#F3F3F3_0%,_#FDD1D1_100%)]",
  grey: "bg-[linear-gradient(90deg,_#F3F3F3_0%,_#C6C2C2_100%)]",
};

export const Skeleton: React.FC<Props> = ({ variant = "default" }) => {
  return (
    <div className={clsx("rounded-3xl w-full h-5 animate-gradient-x", variants[variant])}>
      &nbsp;
    </div>
  );
};
