import { memo } from "react";
import type { SVGProps } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.45597 3.20639L8.29351 4.3688C8.01838 4.64393 8.01838 5.09004 8.29351 5.36523L18.903 16L8.29351 26.6348C8.01838 26.9099 8.01838 27.356 8.29351 27.6312L9.45597 28.7937C9.73111 29.0688 10.1772 29.0688 10.4524 28.7937L22.7478 16.4982C23.0229 16.2231 23.0229 15.777 22.7478 15.5018L10.4524 3.20639C10.1772 2.9312 9.73111 2.9312 9.45597 3.20639Z"
      fill={color}
    />
  </svg>
);

export const ChevronRight = memo(SvgComponent);
