"use client";

import { HTMLAttributes, ReactNode, useState } from "react";
import clsx from "clsx";
import { ChevronDown } from "../../icons";

export interface AccordionProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode;
}

export const Accordion = ({ children, ...props }: AccordionProps) => {
  return <ul {...props}>{children}</ul>;
};

interface AccordionItemProps {
  id: string;
  title: string;
  children?: ReactNode;
  icon?: ReactNode;
  isExpanded?: boolean;
}

export const AccordionItem = ({
  id,
  title,
  children,
  icon,
  isExpanded: controlledExpanded,
}: AccordionItemProps) => {
  const [isExpanded, setExpanded] = useState(controlledExpanded || false);

  const handleClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <li className="border-grey-light border-b-2">
      <h2>
        <button
          id={`accordion--id_${id}`}
          aria-expanded={isExpanded}
          aria-controls={`accordion-item--id_${id}`}
          onClick={handleClick}
          type="button"
          className="flex w-full items-center justify-between gap-5 py-3"
        >
          <span className="flex gap-2">
            {icon}
            <span
              className={clsx("text-h5-sm font-serif", isExpanded && "text-secondary-aubergine")}
            >
              {title}
            </span>
          </span>
          <ChevronDown
            aria-hidden="true"
            role="img"
            width={12}
            className={clsx(
              "transition-1 duration-100",
              isExpanded ? "fill-secondary-aubergine rotate-180" : "rotate-0"
            )}
          />
        </button>
      </h2>
      {children && (
        <div
          role="region"
          aria-labelledby={`accordion--id_${id}`}
          aria-hidden={!isExpanded}
          className={clsx(
            "block overflow-hidden transition-[max-height] duration-100 ease-in-out",
            isExpanded ? "max-h-96" : "max-h-0"
          )}
        >
          <article className="py-3">{children}</article>
        </div>
      )}
    </li>
  );
};
