import { SVGProps, memo } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.7927 9.36943L27.6302 8.20696C27.3543 7.93101 26.9081 7.93101 26.6321 8.20696L15.9996 18.816L5.36714 8.20696C5.0912 7.93101 4.645 7.93101 4.36906 8.20696L3.20659 9.36943C2.93065 9.64537 2.93065 10.0916 3.20659 10.3675L15.5006 22.6615C15.7765 22.9375 16.2227 22.9375 16.4987 22.6615L28.7927 10.3675C29.0686 10.0916 29.0686 9.64537 28.7927 9.36943Z"
      fill={color}
    />
  </svg>
);

export const ChevronDown = memo(SvgComponent);
