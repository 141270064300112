import { memo } from "react";
import type { SVGProps } from "react";

const SvgComponent = ({
  color = "currentColor",
  width = 32,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg width={width} fill="none" viewBox="0 0 36 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 20H19.5V27.5H16.5V20H12L18 14L24 20ZM29.2185 11.138C28.9005 5.2115 24.009 0.5 18 0.5C11.991 0.5 7.0995 5.2115 6.7815 11.138C2.9265 11.8325 0 15.197 0 19.25C0 23.8055 3.6945 27.5 8.25 27.5H13.5V24.5H8.25C5.355 24.5 3 22.145 3 19.25C3 15.0545 6.7185 13.5005 9.6495 13.67C9.399 7.343 12.9615 3.5 18 3.5C23.1795 3.5 26.8365 7.6955 26.3505 13.67C28.968 13.601 33 14.7965 33 19.25C33 22.145 30.645 24.5 27.75 24.5H22.5V27.5H27.75C32.3055 27.5 36 23.8055 36 19.25C36 15.197 33.0735 11.8325 29.2185 11.138Z"
      fill={color}
    />
  </svg>
);

export const Upload = memo(SvgComponent);
