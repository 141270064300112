const colors: Record<Colors, string> = {
  yellow: "bg-signal-yellow",
  green: "bg-signal-green",
  red: "bg-secondary-pink",
};

const bgColor: Record<Colors, string> = {
  yellow: "bg-signal-yellow/25",
  green: "bg-signal-green/25",
  red: "bg-secondary-pink/25",
};

type Colors = "yellow" | "green" | "red";
interface Props {
  color: Colors;
  label: string;
}

export const Pill: React.FC<Props> = ({ color, label }) => {
  return (
    <div
      className={`${bgColor[color]} text-label-xs text-primary-black tablet:h-[40px] tablet:text-forms-md inline-flex h-[30px] items-center rounded-full px-4 py-2 font-bold`}
    >
      <span className={`${colors[color]} mr-1 inline-block size-2 rounded-full`} />
      {label}
    </div>
  );
};
